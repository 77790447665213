/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        import ExceptionHandler = System.Aop.ExceptionHandler;

        export class App implements Contract.Application.IApp {
            protected inject: Contract.Application.IInject;
            protected viewManager: Contract.Ui.IViewManager;
            protected modelResolver: Contract.Core.IModelResolver;
            protected event: Contract.Application.IEventAggregator;
            protected action: Contract.Application.IAction;
            protected invoker: Contract.Ui.IInvoker;
            protected notifyBuffer: Contract.Core.IChangeBuffer;
            protected modelFactory: Contract.Core.IModelFactory;
            protected models: Contract.Core.IBuffer;
            protected deltaHandler: Contract.Application.IDeltaHandler;
            protected typeService: Contract.Core.ITypeService;
            protected commandFactory: Contract.Ui.ICommandFactory;
            protected httpService: Contract.Server.IServer;
            protected workQueue: Contract.Application.IWorkQueue;

            onRegister: (inject: Mobilize.Contract.Application.IInject) => void;

            onResolver: (modelResolver: Mobilize.Contract.Core.IModelResolver) => void;

            onInvoker: (invoker: Mobilize.Contract.Ui.IInvoker) => void;

            onViewHandler: (viewManager: Mobilize.Contract.Ui.IViewManager) => void;

            onCommandRegister: (factory: Mobilize.Contract.Ui.ICommandFactory) => void;

            onInitialized: () => void;

            onError: (error) => void;

            constructor(inject: Contract.Application.IInject = null) {
                this.inject = inject || Inject.Instance;
            }

            protected handleResponse(response: Contract.Server.IResponse) {
                var that = this;
                var requirements = this.getDependenciesForTypes(response.modelDeltaTypes);
                if (requirements && requirements.length > 0) {
                    requirejs(requirements, () => {
                        that.deltaHandler.executeWorkers(response);
                    });
                } else {
                    that.deltaHandler.executeWorkers(response);
                }
            }

            protected getDependenciesForTypes(modelTypes: any): Array<string> {
                var requirements = [];
                if (modelTypes && modelTypes.length) {
                    for (var mType of modelTypes) {
                        // requiremets are assigned to multiples of 10
                        if (mType % 10 === 0) {
                            // dependencies are currently only for user controls
                            var dependency = this.getDependencyFullName(mType);
                            if (dependency && dependency.indexOf("UpgradeHelpers") === -1 && !requirejs.defined(dependency)) {
                                requirements.push(dependency);
                            }
                        }
                    }
                }
                return requirements;
            }

            protected getDependencyFullName(modelType: string) {
                var typeName = this.getTypeInfo(modelType);
                if (typeName) {
                    typeName = "usercontrols/" + typeName;
                }
                return typeName;
            }

            public init(controller: string = "Home", action: string = "AppState", callback?: () => void): void {
                this.register();
                this.initResolve();
                // the types info is required before the first load of the application state
                this.loadModelTypes(() => {
                    this.loadInitialState(controller, action, callback);
                });
            }

            @ExceptionHandler()
            public sendAction(action: Contract.Application.IActionModel) {
                this.action.send(action);
            }

            @ExceptionHandler()
            public loadInitialState(controller: string, action: string, callback?: () => void) {
                var self = this;
                this.action.send(new ActionModel(controller, action, null, null, () => self.afterInit(callback), new Server.RequestConfig(Contract.Server.RequestType.ModelRequest, true, "")));
            }

            public isSyncronizing(): boolean {
                return this.modelResolver.isCoreSynchronizing();
            }

            public executeSafely(data: Contract.Application.IWorkData) {
                if (this.isSyncronizing()) {
                    this.workQueue.addToWorkQueue(data);
                } else {
                    data.fn();
                }
            }

            public getTypeInfo(modelType: string): string {
                return this.typeService.getTypeInfo(modelType);
            }

            public publishEvent(event: string, data?: any) {
                this.event.publish(event, data);
            }

            private afterInit(callback: () => void = undefined) {
                if (this.onInitialized) {
                    this.onInitialized();
                }
                if (callback) {
                    callback();
                }
            }

            private register() {
                this.inject.register(Contract.Application.Constants.Server, Server.HttpServer, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.EventAggregator, EventAggregator, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.ModelResolver, Core.ModelResolver, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.Action, ActionService, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.ViewManager, Ui.HunterViewManager, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.Buffer, Core.ModelCollection, Contract.Application.Resolve.Request);
                this.inject.register(Contract.Application.Constants.Invoker, Ui.Invoker, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.NotifyBuffer, Core.DirtyCache, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.Library, Library, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.UrlResolver, Server.UrlResolver, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.ModelFactory, Core.ModelFactory, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.ModalFactory, Ui.ModalFactory, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.RequestBuilder, Server.RequestBuilder, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.DeltaHandler, Application.DeltaHandler, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.WorkQueue, Application.WorkQueue, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.TypeService, Core.TypeService, Contract.Application.Resolve.Singleton);
                this.inject.register(Contract.Application.Constants.CommandFactory, Ui.CommandFactory, Contract.Application.Resolve.Singleton);
                this.executeOnRegister();
            }

            private initResolve(): void {
                this.viewManager = this.inject.resolve(Contract.Application.Constants.ViewManager);
                this.modelResolver = this.inject.resolve(Contract.Application.Constants.ModelResolver);
                this.deltaHandler = this.inject.resolve(Contract.Application.Constants.DeltaHandler);
                this.event = this.inject.resolve(Contract.Application.Constants.EventAggregator);
                this.action = this.inject.resolve(Contract.Application.Constants.Action);
                this.invoker = this.inject.resolve(Contract.Application.Constants.Invoker);
                this.notifyBuffer = this.inject.resolve(Contract.Application.Constants.NotifyBuffer);
                this.modelFactory = this.inject.resolve(Contract.Application.Constants.ModelFactory);
                this.commandFactory = this.inject.resolve(Contract.Application.Constants.CommandFactory);
                this.httpService = this.inject.resolve(Contract.Application.Constants.Server);
                this.workQueue = this.inject.resolve(Contract.Application.Constants.WorkQueue);
                this.typeService = this.inject.resolve(Contract.Application.Constants.TypeService);
                this.modelResolverInit();
                this.deltaHandlerInit();
                this.executeOnInvoker();
                this.executeOnResolver();
                this.executeOnViewResolver();
                this.executeOnCommandRegister();
                this.subscribe();
                this.initModules();
            }

            private modelResolverInit() {
                this.modelResolver.registerBehavior(new Core.HierarchyBehavior());
                this.modelResolver.registerBehavior(new Core.PointerBehavior());
                this.modelResolver.registerBehavior(new Core.PropagateChangeIdBehavior());
                this.modelResolver.registerNotifier(new Core.ChangeNotifier());
                this.modelResolver.registerBehavior(new Core.ModalVisibilityManagementBehavior());
            }

            private deltaHandlerInit() {
                // this order must be maintained during the workers execution
                this.deltaHandler.registerWorker(new Application.DeltaWorker.SwitchIdWorker());
                this.deltaHandler.registerWorker(new Application.DeltaWorker.RemovedIdWorker());
                this.deltaHandler.registerWorker(new Application.DeltaWorker.ModelDeltaWorker());
                this.deltaHandler.registerWorker(new Application.DeltaWorker.CommandsWorker());
                this.deltaHandler.registerWorker(new Application.DeltaWorker.HunterViewDeltaWorker());
            }

            private executeOnCommandRegister() {
                this.commandFactory.register(Mobilize.Ui.Command.Commands.OpenView, Ui.Command.OpenView);
                this.commandFactory.register(Mobilize.Ui.Command.Commands.CloseView, Ui.Command.CloseView);
                this.commandFactory.register(Mobilize.Ui.Command.Commands.MessageBox, Ui.Command.MessageBox);
                this.commandFactory.register(Mobilize.Ui.Command.Commands.InputBox, Ui.Command.InputBox);
                if (this.onCommandRegister) {
                    this.onCommandRegister(this.commandFactory);
                }
            }

            private executeOnInvoker() {
                this.invoker.register(this.viewManager);
                if (this.onInvoker) {
                    this.onInvoker(this.invoker);
                }
            }

            private executeOnRegister() {
                if (this.onRegister) {
                    this.onRegister(this.inject);
                }
            }

            private executeOnResolver() {
                if (this.onResolver) {
                    this.onResolver(this.modelResolver);
                }
            }

            private executeOnViewResolver() {
                if (this.onViewHandler) {
                    this.onViewHandler(this.viewManager);
                }
            }

            private handleError(exception: Contract.System.IException) {
                this.invoker.invoke(new Mobilize.Ui.Command.ModalCommandLoading({ show: false }));
                if (exception instanceof System.NetworkException) {
                    this.handleNetworkError(exception as System.NetworkException);
                } else {
                    this.invoker.invoke(new Mobilize.Ui.Command.ModalCommandAlert(new Alert("Error", "There are some problems", exception.message, "error")));
                }
                // always show in console for consistency of tracing
                console.error(exception.message);
            }

            private handleNetworkError(exception: Contract.System.INetworkException) {
                if (exception.code === Contract.System.ErrorCode.SessionExpire) {
                    this.invoker.invoke(new Mobilize.Ui.Command.ModalCommandSessionExpired(new Alert("Session Expired", "Detail ", exception.message, "error")));
                } else {
                    this.invoker.invoke(new Mobilize.Ui.Command.ModalCommandAlert(new Alert("Network Error", "There are some problems", exception.message, "error")));
                }
            }

            private subscribe() {
                this.event.subscribe(Contract.Application.Events.ApplyDeltas, (response: Contract.Server.IResponse) => this.handleResponse(response));
                this.event.subscribe(Contract.Application.Events.ActionSending, () => this.invoker.invoke(new Mobilize.Ui.Command.ModalCommandLoading({ show: true })));
                this.event.subscribe(Contract.Application.Events.ActionComplete, () => this.invoker.invoke(new Mobilize.Ui.Command.ModalCommandLoading({ show: false })));
                this.event.subscribe(Contract.Application.Events.Error, (exception) => this.handleError(exception));
            }

            private initModules() {
                const buffer = this.inject.resolve(Contract.Application.Constants.Buffer);
                this.modelResolver.init(buffer);
                this.notifyBuffer.init(buffer);
                this.models = buffer;
                this.viewManager.init(null, buffer);
            }

            private loadModelTypes(callback: () => void) {
                this.httpService.post("TypeInfo/GetAll", null, (response) => {
                    this.typeService.init(response);
                    callback();
                });
            }
        }
    }
}
