/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application.DeltaWorker {
        export class SwitchIdWorker implements Contract.Application.IDeltaWorker {
            private modelResolver: Contract.Core.IModelResolver;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.modelResolver = this.inject.resolve(Contract.Application.Constants.ModelResolver);
            }

            process(response: Contract.Server.IResponse) {
                if (response.switchIds) {
                    this.modelResolver.switchIds(response.switchIds);
                }
            }

            get Order(): Contract.Core.Order {
                return Contract.Core.Order.ORD;
            }
        }
    }
}
