/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        export abstract class View implements Mobilize.Contract.Ui.IView {

            public name: string;
            public model: Mobilize.Contract.Core.IModel;
            public onLoad: (sender, events) => void;
            public onPostLoad: (sender, events) => void;
            private invoker: Contract.Ui.IInvoker;
            private timersToCleanup: Array<any>;

            /*tslint:disable:no-string-literal */
            constructor(model: Mobilize.Contract.Core.IModel, inject: Mobilize.Application.Inject = null) {
                const _inject = inject || Mobilize.Application.Inject.Instance;
                this.model = model;
                this.model["view"] = this;
                this.invoker = _inject.resolve(Contract.Application.Constants.Invoker);
                this.bindings();
                this.timersToCleanup = new Array();
            }

            close() {
                this.cleanupTimers();
                this.dispose();
            }

            requestClose() {
                this.invoke(new Command.ClientClose(this));
            }

            load() {
                if (this.onLoad) {
                    this.onLoad(this, null);
                }
                this.render();
                if (this.onPostLoad) {
                    this.onPostLoad(this, null);
                }
            }

            bind(action: string, delegate: (sender: Contract.Ui.IView, action: string, event: any) => void) {
                const self = this;
                self.model[action] = e => {
                    if (delegate) {
                        return delegate(self, action, e);
                    }
                };
            }

            abstract bindings();

            abstract render();

            abstract dispose();

            invoke(command: Mobilize.Contract.Ui.ICommand): void {
                this.invoker.invoke(command);
            }

            registerTimer(timerInfo: any) {
                this.timersToCleanup.push(timerInfo);
            }

            private cleanupTimers(): void {
                this.timersToCleanup.forEach((timerInfo) => {
                    if (timerInfo && timerInfo.clearTimer) {
                        timerInfo.clearTimer();
                    }
                });
            }
        }
    }
}
