/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class ModalCommandSessionExpired extends ModalCommand {
            private _settings: any;

            constructor(settings: any) {
                super();
                this._settings = settings;
            }

            modalName(): string {
                return "exp";
            }

            parameters() {
                return this._settings;
            }
        }
    }
}
