/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        export class DirtyCache implements Contract.Core.IChangeBuffer {
            private items: Array<DirtyModel>;
            private event: Contract.Application.IEventAggregator;
            private entity: Contract.Core.IEntity;

            constructor(inject: Contract.Application.IInject = null) {
                const _inject = inject || Mobilize.Application.Inject.Instance;
                this.items = Array<DirtyModel>();
                this.event = _inject.resolve(Contract.Application.Constants.EventAggregator);
            }

            change(model: Contract.Core.IModel, property: string) {
                // add only valid changes
                if (property && model.UniqueID && !model.isCoreSynchronizing()) {
                    if (this.items.some(item => item.name === model.UniqueID)) {
                        this.items.filter(item => item.name === model.UniqueID).forEach(item => {
                            item.properties.push(new DirtyProperty(property, model[property]));
                        });
                    } else {
                        this.items.push(new DirtyModel(model.UniqueID, new Array<DirtyProperty>(new DirtyProperty(property, model[property]))));
                    }
                }
            }

            init(entity: Mobilize.Contract.Core.IEntity) {
                this.entity = entity;
                this.setListener();
            }

            getChanges(): any {
                const collection = {};
                while (this.items.length > 0) {
                    var currentItem = this.items.pop();
                    collection[currentItem.name] = currentItem.toReduce();
                }
                return collection;
            }

            private setListener() {
                const self = this;
                this.event.subscribe(Contract.Application.Events.Change, (event) => self.change(event.model, event.property));
            }
        }
    }
}
