/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        import ClientBehavior = Mobilize.Contract.Core.IClientBehavior;
        import Entity = Mobilize.Contract.Core.IEntity;
        import IModel = Mobilize.Contract.Core.IModel;
        import Order = Contract.Core.Order;

        export class DefaultValueBehavior implements ClientBehavior {
            aliasService: Contract.Core.ITypeService;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.aliasService = this.inject.resolve(Contract.Application.Constants.TypeService);
            }


            apply(model: IModel, root: Entity) {
                if (!model.isPointer) {
                    const name = model.uniqueName();
                    if (name.length > 0) {
                        var k = model["@k"];
                        if (k == undefined) {
                            return;
                        }

                        var typeInfos = this.aliasService.getAlias(k.toString());
                        if (typeInfos == undefined) {
                            return;
                        }

                        var typeInfoKeys = Object.keys(typeInfos);
                        for (var i = 0; i < typeInfoKeys.length; i++) {
                            var typeInfo = typeInfos[typeInfoKeys[i]];
                            var propRealName = typeInfo[0];
                            var propDefaultValue = typeInfo[1];
                            var propType = typeInfo[2];
                            //In case of simple types or String(20 is string).
                            if (propDefaultValue != null &&
                                (propType == 0 || propType == 20) &&
                                model[propRealName] == undefined)
                            {
                                    model[propRealName] = propDefaultValue;
                            }
                        }

                    }
                }
            }

            get Order(): Order {
                return Order.PRE;
            }
        }
    }
}

