/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class Alert {
            _caption: string;
            _message: string;
            _info: string;
            _type: string;
            _tooltip: string;

            constructor(caption: string = "alert", message: string, info: string = "", type: string = "success", tooltip: string = "details") {
                this._caption = caption;
                this._message = message;
                this._info = info;
                this._type = type;
                this._tooltip = tooltip;
            }

            get caption(): string {
                return this._caption;
            }

            get message(): string {
                return this._message;
            }

            get info() {
                return this._info;
            }

            get tooltip() {
                return this._tooltip;
            }

        }
    }
}
