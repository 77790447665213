/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        export class DirtyProperty {
            private _name: string;
            private _value: string;

            constructor(name: string, value: string) {
                this._name = name;
                this._value = value;
            }

            get name(): string {
                return this._name;
            }

            set name(data: string) {
                this._name = data;
            }

            get value(): string {
                return this._value;
            }

            set value(data: string) {
                this._value = data;
            }
        }
    }
}
