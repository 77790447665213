/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace System {
        export class Exception implements Contract.System.IException {
            private _message: string;

            constructor(message: string) {
                this._message = message;
            }

            get message(): string {
                return this._message;
            }
        }
    }
}
