/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class InputBox implements Contract.Ui.ICommand {
            private _input: string;
            private _caption: string;
            private _text: string;
            private _icons: number;

            constructor(data: any) {
                this._input = data.parameters.defaultResponse;
                this._caption = data.parameters.title;
                this._text = data.parameters.prompt;
                this._icons = data.parameters.icons;
            }

            get name(): string {
                return Commands.InputBox;
            }

            get input(): string {
                return this._input;
            }

            get caption(): string {
                return this._caption;
            }
            get text(): string {
                return this._text;
            }
            get icons(): number {
                return this._icons;
            }
        }
    }
}
