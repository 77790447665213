module Mobilize {
    export namespace Application {
        export class WorkQueue implements Contract.Application.IWorkQueue {
            private event: Contract.Application.IEventAggregator;
            private queue: any;
            private executingKey: string;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject ? inject : Application.Inject.Instance;
                this.event = this.inject.resolve(Contract.Application.Constants.EventAggregator);
                this.queue = {};
                this.init();
            }
            addToWorkQueue(data: Contract.Application.IWorkData) {
                // avoid reinserting the key during its execution
                if (data.key && data.key !== this.executingKey) {
                    this.queue[data.key] = data.fn;
                }
            }

            executeWorkQueue() {
                for (var key in this.queue) {
                    if (this.queue.hasOwnProperty(key)) {
                        var fn = this.queue[key];

                        if (typeof (fn) === "function") {
                            try {
                                this.executingKey = key;
                                fn();
                            } finally {
                                this.executingKey = "";
                            }
                        }

                        delete this.queue[key];
                    }
                }
            }

            private init() {
                this.event.subscribe(Contract.Application.Events.AddToWorkQueue, (data) => { this.addToWorkQueue(data); });
                this.event.subscribe(Contract.Application.Events.ExecWorkQueue, () => { this.executeWorkQueue(); });
            }

        }
    }
}
