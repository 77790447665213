/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class MessageBox implements Mobilize.Contract.Ui.ICommand {
            private _sender: Mobilize.Contract.Ui.IView;
            private _action: any;
            private _input: string;
            private _inputRequest: string;
            private _caption: string;
            private _text: string;
            private _buttons: number;
            private _icons: number;


            constructor(data: any) {
                this._input = data.parameters.input;
                this._inputRequest = data.parameters.inputRequest;
                this._caption = data.parameters.caption;
                this._text = data.parameters.text;
                this._buttons = data.parameters.buttons;
                this._icons = data.parameters.icons;
            }

            get sender(): Mobilize.Contract.Ui.IView {
                return this._sender;
            }

            get action(): any {
                return this._action;
            }

            get name(): string {
                return Commands.MessageBox;
            }

            get input(): string {
                return this._input;
            }
            get inputRequest(): string {
                return this._inputRequest;
            }
            get caption(): string {
                return this._caption;
            }
            get text(): string {
                return this._text;
            }
            get buttons(): number {
                return this._buttons;
            }
            get icons(): number {
                return this._icons;
            }

            get status(): string {
                return "modal-overlay dialog dialog-open";
            }
        }

    }
}
