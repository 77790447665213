/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Contract.Application {
        export class Constants {
            static EventAggregator = "eventAggregator";
            static ViewManager = "viewManager";
            static ViewResolver = "viewResolver";
            static ModelResolver = "modelResolver";
            static Server = "server";
            static Library = "library";
            static Buffer = "buffer";
            static Action = "action";
            static Invoker = "invoker";
            static NotifyBuffer = "notifyBuffer";
            static UrlResolver = "urlResolver";
            static ModelFactory = "modelFactory";
            static ModalFactory = "modalFactory";
            static RequestBuilder = "requestBuilder";
            static DeltaHandler = "deltaHandler";
            static TypeService = "typeService";
            static WorkQueue = "workQueue";
            static CommandWorker = "commandWorker";
            static CommandFactory = "commandFactory";
        }

        export class ContentType {
            static application_json = "application/json; charset = utf - 8";
            static multipart_formdata = "multipart/form-data";
        }

        export class Events {
            static Change = "change";
            static ActionComplete = "actionComplete";
            static ActionError = "actionError";
            static ActionSending = "ActionSending";
            static ApplyDeltas = "applyDeltas";
            static Error = "error";
            static AddToWorkQueue = "addToWorkQueue";
            static ExecWorkQueue = "execToWorkQueue";
            static PreActionCommandGeneratorRegistration = "preActionCommandGeneratorRegistration";
            static PreActionCommandGeneratorDeregistration = "preActionCommandGeneratorDeregistration";
        }

        export enum Resolve {
            Singleton,
            Request
        }

        export interface IDomHandler {
            append: any;
            attr: any;
            clone: any;
            prepend: any;
            prop: any;
            remove: any;
            has: any;
            find: any;
            val: any;
            data: any;
            fadeIn: any;
            fadeOut: any;
        }

        export interface IDom {
            find: (selector: string) => IDomHandler;
            extend: (object1: Object, object2: Object) => any;
        }

        export interface IQuery {
            ajax: any;
            get: any;
        }

        export interface ILibrary {
            query: IQuery;
            dom: IDom;
        }

        export interface IActionModel {
            controller: string;
            action: string;
            item: Contract.Core.IModel;
            parameters: any;
            callback: (response: Contract.Server.IResponse) => void;
            requestConfig: Contract.Server.IRequestConfig;
        }

        export interface IApp {
            onError: (error) => void;
            onInitialized: () => void;
            onRegister: (inject: IInject) => void;
            onResolver: (modelResolver: Contract.Core.IModelResolver) => void;
            onViewHandler: (viewManager: Mobilize.Contract.Ui.IViewManager) => void;
            init(controller?: string, action?: string, callback?: () => void): void;
            sendAction(action: IActionModel);
            loadInitialState(controller: string, action: string, callback: () => void);
            publishEvent(event: string, data?: any);
            executeSafely(data: Contract.Application.IWorkData);
            getTypeInfo(modelType: string): string;
        }

        export interface IEventAggregator {
            publish(event: string, data?: any);
            subscribe(event: string, lambda: (data: any) => void);
        }

        export interface IInject {
            register(name: string, type: any, resolve: Resolve);
            resolve(name: string);
        }

        export interface IAction {
            send(action: IActionModel, includeDirty?: boolean);
        }

        export interface IDictionary {
            length: number;
            add(key: string, value: any): void;
            remove(key: string): void;
            containsKey(key: string): boolean;
            keys(): string[];
            values(): any[];
            value(key: string): any;
            replace(key, value: any);

        }

        export interface IDeltaHandler {
            registerWorker(worker: IDeltaWorker);
            executeWorkers(resoponse: Server.IResponse);
        }

        export interface IDeltaWorker {
            Order: Core.Order;
            process(response: Server.IResponse);
        }

        export interface IWorkQueue {
            addToWorkQueue(data: IWorkData);
            executeWorkQueue();
        }

        export interface IWorkData {
            key: string;
            fn: () => void;
        }

        export interface ICommandWorker {
            Order: Core.Order;
            process(response: Server.IResponse);
        }

        export interface IRequestCommand {
            command: string;
            data: any;
        }
    }
}
