/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace System.Aop {
        export function Component(templateUrl: string, styleCss: string) {
            var resolveTemplate = (self: any) => {
                const http: Contract.Server.IServer = Application.Inject.Instance.resolve(Contract.Application.Constants.Server);
                const library: Contract.Application.ILibrary = Application.Inject.Instance.resolve(Contract.Application.Constants.Library);
                http.get(styleCss, null, (styles) => {
                    library.dom.find("head").append(`<style type='text/css'>${styles}</style>`);
                    http.get(templateUrl, null, (response) => {
                        library.dom.find("body").append(response);
                        self.executeLoad();
                    });
                });
            };

            return (target: Function) => {
                const newConstructor: any = function(...args) {
                    target.apply(this, args);
                    resolveTemplate(this);
                };
                newConstructor.prototype = Object.create(target.prototype);
                newConstructor.prototype.constructor = target;
                return newConstructor;
            };
        }
    }
}
