/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class ClientClose implements Mobilize.Contract.Ui.ICommand {
            private _controller: string;
            private _action: string;
            private _args: any;

            constructor(sender: Mobilize.Contract.Ui.IView, controller: string = "WebMapViewManager", action: string = "QueryClose") {
                this._controller = controller;
                this._action = action;
                this._args = { viewId: sender.model.UniqueID };
            }

            get controller(): string {
                return this._controller;
            }

            get args(): any {
                return this._args;
            }

            get action(): string {
                return this._action;
            }

            get name(): string {
                return Commands.ClientCloseCommand;
            }
        }
    }
}
