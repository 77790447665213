/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        import IModel = Mobilize.Contract.Core.IModel;

        export class ModelCollection implements Contract.Core.IBuffer {
            models: Contract.Application.IDictionary;

            constructor() {
                this.models = new Application.Dictionary();
            }

            add(model: IModel) {
                if (this.models.containsKey(model.UniqueID)) {
                    if (this.models.value(model.UniqueID).IsTempModel) {
                        this.models.remove(model.UniqueID);
                    } else {
                        throw "there is a problem, can't add a repeated uniqueID into the model collection.";
                    }
                }
                this.models.add(model.UniqueID, model);
            }

            apply(fn: (model: IModel) => any) {
                if (fn) {
                    this.models.values().forEach((item) => fn(item));
                }
            }

            getModel(key: string): IModel {
                if (this.models.containsKey(key)) {
                    return this.models.value(key);
                }
                return null;
            }

            getParentByKey(key: string): IModel {
                const parentUniqueID = key.substr(key.indexOf(Model.separator) + Model.separator.length);
                return this.getModel(parentUniqueID);
            }

            getParentByModel(model: IModel): IModel {
                return this.getParentByKey(model.UniqueID);
            }

            addRange(array: Array<Contract.Core.IModel>) {
                array.forEach((element) => {
                    this.add(element);
                });
            }

            get length() {
                return this.models.length;
            }

            deleteCascade(key: string) {
                const model = this.getModel(key);
                if (model) {
                    for (let propertyName in model) {
                        if (model.hasOwnProperty(propertyName)) {
                            const property = model[propertyName];
                            if (property && property.UniqueID) {
                                // the property's UniqueID might be different if the value was set by a pointer, so the UniqueID is recreated for the delete.
                                propertyName = propertyName === "Items" ? "_items" : propertyName;
                                const pointerName = propertyName + Model.separator + key;
                                this.deleteCascade(pointerName);
                            }
                        }
                    }
                    this.models.remove(key);
                }
            }

            replace(model: IModel) {
                this.models.replace(model.UniqueID, model);
            }

            switchIds(oldUniqueId: string, newUniqueId: string) {
                const model = this.models.value(oldUniqueId);
                let tempModel = this.models.value(newUniqueId);
                if (model) {
                    model.UniqueID = newUniqueId;
                    this.models.remove(oldUniqueId);
                    this.models.add(newUniqueId, model);

                    // update the models's index in the parent array after switching the IDs
                    var parentArray = this.getParentByKey(newUniqueId) as any;
                    if (parentArray && parentArray.isArray) {
                        if (tempModel) {
                            tempModel.UniqueID = oldUniqueId;
                        } else {
                            tempModel = new Model(oldUniqueId);
                        }
                        tempModel.IsTempModel = true;

                        // the tempModel is expected to be removed when processing the deltas
                        this.models.add(oldUniqueId, tempModel);

                        let radix = 10;
                        const oldIndex = parseInt(oldUniqueId.split(Model.separator)[0], radix);
                        const newIndex = parseInt(newUniqueId.split(Model.separator)[0], radix);

                        // replace the models in the parent array with splice to use overloaded array functions (if any)
                        parentArray.splice(oldIndex, 1, tempModel);
                        parentArray.splice(newIndex, 1, model);
                    }
                }
            }

            exists(model: Contract.Core.IModel): boolean {
                return this.models.containsKey(model.UniqueID) && !this.models.value(model.UniqueID).IsTempModel;
            }

            search(name: string): IModel {

                var model = null;
                this.models.values().forEach((item) => {

                    if (item && item.UniqueID && item.UniqueID.indexOf(name) > -1) {
                        model = item;
                    }
                });

                return model;
            }

            toArray(): Array<IModel> {
                return this.models.values();
            }
        }
    }
}
