/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class Library implements Contract.Application.ILibrary {
            private _query: Contract.Application.IQuery;
            private _dom: Contract.Application.IDom;
            private domWrapper = {
                find: (selector) => $(selector) as Contract.Application.IDomHandler,
                extend: (object1, object2) => $.extend(object1, object2) as Object
            };

            constructor(query: Contract.Application.IQuery = null, dom: Contract.Application.IDom = null) {
                this._query = query || $;
                this._dom = dom || this.domWrapper;
            }

            get query(): Contract.Application.IQuery {
                return this._query;
            }

            get dom(): Contract.Application.IDom {
                return this._dom;
            }
        }
    }
}
