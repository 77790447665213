/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Server {
        export class Request implements Contract.Server.IRequest {
            dirty: Array<any>;
            vm: string;
            parameters: Array<any>;
            commands: Array<any>;

            constructor() {
                this.commands = [];
            }

        }
    }
}
