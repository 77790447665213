/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class CloseView implements Mobilize.Contract.Ui.ICommand {
            private _arguments: Mobilize.Contract.Ui.IView;
            private _uniqueID: any;

            constructor(data: any) {
                this._uniqueID = data.UniqueID;
            }

            get sender(): any {
                return this._arguments;
            }

            get UniqueID(): any {
                return this._uniqueID;
            }

            get name(): string {
                return Commands.CloseView;
            }
        }
    }
}
