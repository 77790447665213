/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Contract.System {
        export enum ErrorCode {
            ServerError = 500,
            SessionExpire = 440,
            Authentication = 401,
            BadRequest = 400,
            AccessDenied = 403
        }

        export interface IException {
            message: string;
        }

        export interface INetworkException extends IException {
            code: ErrorCode;
        }
    }
}
