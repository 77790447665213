/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Server {
        export class HttpServer implements Contract.Server.IServer {
            private library: Contract.Application.ILibrary;
            private event: Contract.Application.IEventAggregator;
            private inject: Contract.Application.IInject;

            constructor(inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.library = this.inject.resolve(Contract.Application.Constants.Library);
                this.event = this.inject.resolve(Contract.Application.Constants.EventAggregator);
            }

            get(url: string, params: any[], callback: (response: any) => void) {
                const request = this.library.query.get(url, params);
                request.done(result => this.ExecuteResponse(result, callback));
                request.fail((response) => {
                    if (response.status) {
                        this.event.publish(Contract.Application.Events.Error, new System.NetworkException(response.responseText, response.status));
                    }
                    // the ActionService needs to be called on failure, to clear the pending status of the request.
                    if (callback) {
                        callback(response);
                    }
                });
            }

            post(url: string, data: any, callback: (response: any) => void, config?: Contract.Server.IRequestConfig) {
                const request = this.sendRequest(url, data, config);
                request.always(() => {
                    this.event.publish(Contract.Application.Events.ActionComplete);
                });
                request.done((response) => {
                    if (callback) {
                        callback(response);
                    }
                });
                request.fail((response) => {
                    if (response.status) {
                        this.event.publish(Contract.Application.Events.Error, new System.NetworkException(response.responseText, response.status));
                    }
                    // the ActionService needs to be called on failure, to clear the pending status of the request.
                    if (callback) {
                        callback(response);
                    }
                });
            }

            private ExecuteResponse(response: any, callback: (response: any) => void) {
                this.validateResponse(response);
                if (callback) {
                    callback(response);
                }
            }

            private validateResponse(response: any) {
                if (response.ErrorOcurred) {
                    const message = response.ExMessage + "<pre>" + response.ExStackTrace + "<pre\>";
                    throw new System.NetworkException(message, Contract.System.ErrorCode.ServerError);
                }
            }

            private sendRequest(url: string, data: any, config?: Contract.Server.IRequestConfig): any {
                var contentType = (config && (config.contentType !== undefined)) ? config.contentType : Contract.Application.ContentType.application_json;
                switch (contentType) {
                    case Contract.Application.ContentType.multipart_formdata:
                        return this.processFormData(url, data);
                    case Contract.Application.ContentType.application_json:
                        return this.processApplicationJSON(url, data);
                    default:
                        return this.processDefault(url, data, contentType);
                }
            }

            private processFormData(url: string, data: any): any {
                return this.library.query.ajax(url, {
                    type: "POST",
                    headers: { WM: true },
                    data: data,
                    contentType: false,
                    processData: false,
                    beforeSend: () => {
                        this.event.publish(Contract.Application.Events.ActionSending);
                    }
                });
            }

            private processApplicationJSON(url: string, data: any): any {
                return this.library.query.ajax(url, {
                    type: "POST",
                    headers: { WM: true },
                    dataType: "json",
                    data: data,
                    contentType: Contract.Application.ContentType.application_json,
                    beforeSend: () => {
                        this.event.publish(Contract.Application.Events.ActionSending);
                    }
                });
            }

            private processDefault(url: string, data: any, contentType: string): any {
                return this.library.query.ajax(url, {
                    type: "POST",
                    headers: { WM: true },
                    dataType: "json",
                    data: data,
                    contentType: contentType,
                    beforeSend: () => {
                        this.event.publish(Contract.Application.Events.ActionSending);
                    }
                });
            }
        }
    }
}
