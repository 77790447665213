/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class InjectMember {
            private _constructor: any;
            private resolve: Contract.Application.Resolve;
            private instance: any;

            constructor(_constructor: any, resolve: Contract.Application.Resolve) {
                this._constructor = _constructor;
                this.resolve = resolve;
            }

            public getObject(inject: Contract.Application.IInject) {
                if (this.resolve === Contract.Application.Resolve.Singleton) {
                    if (this.instance === undefined) {
                        this.instance = new this._constructor(inject);
                    }
                    return this.instance;
                }
                return new this._constructor(inject);
            }
        }
    }
}
