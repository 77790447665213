/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        export class HunterViewManager implements Contract.Ui.IViewManager {
            private inject: Contract.Application.IInject;
            private views: Mobilize.Contract.Application.IDictionary;
            private commands: Contract.Application.IDictionary;
            private action: Contract.Application.IAction;
            private viewResolver: Contract.Ui.IViewResolver;
            private modalFactory: Contract.Ui.IModalFactory;
            private onRegisterCommand: () => void;
            private models: Mobilize.Contract.Core.IEntity;

            constructor(inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.viewResolver = this.inject.resolve(Contract.Application.Constants.ViewResolver);
                this.views = new Mobilize.Application.Dictionary();
                this.commands = new Application.Dictionary();
                this.action = this.inject.resolve(Contract.Application.Constants.Action);
                this.modalFactory = this.inject.resolve(Contract.Application.Constants.ModalFactory);
                this.initializeCommand();
            }

            createView(model: Contract.Core.IModel, items?: Array<string>, views?: Array<Contract.Ui.IView>): void {
                const self = this;
                if (items && views) {
                    this.viewResolver.resolve(model, view => {
                        views.push(view);
                        self.viewCreated(view);
                        if (views.length == items.length) {
                            items.forEach(c => views.filter(d => d.model.UniqueID == c).forEach(v => v.load()));
                        }

                    });
                }
                else {
                    this.viewResolver.resolve(model, view => self.viewCreated(view));
                }
            }

            viewCreated(view: Contract.Ui.IView) {
                this.views.add(view.model.UniqueID, view);
            }

            deleteView(viewId: string): void {
                const view = this.views.value(viewId);
                if (view) {
                    view.close();
                    this.views.remove(viewId);
                }
            }

            getView(model: Contract.Core.IModel): Contract.Ui.IView {
                const uniqueId = model.UniqueID;
                return this.views.value(uniqueId);
            }

            removeViews(response): void {
                var data = response.viewDeltas;
                if (data.RemovedViews) {
                    for (let i = 0; i < data.RemovedViews.length; i++) {
                        const viewModelId = data.RemovedViews[i];
                        if ((<any>response.removeIds).includes(viewModelId)) {
                            this.deleteView(viewModelId);
                            this.models.deleteCascade(viewModelId);
                        } else {
                            $("#" + viewModelId).parent().hide();
                            $(".k-overlay").hide();
                        }
                    }
                }
            }

            execute(command: Contract.Ui.ICommand) {
                if (this.commands[command.name]) {
                    this.commands[command.name](command);
                }
            }

            hasCommand(command: Contract.Ui.ICommand): boolean {
                return this.commands.containsKey(command.name);
            }

            registerCommand(name: string, fn: (command: Contract.Ui.ICommand) => void) {
                this.commands.add(name, fn);
            }

            registerModal(modal: Mobilize.Contract.Ui.IModal) {
                this.modalFactory.register(modal);
            }

            init(views: any, models: Contract.Core.IEntity) {
                this.models = models;
                this.loadFirstViews(views);
            }

            loadFirstViews(views: any) {
                if (views && views.LoadedViews) {
                    var orderViews = [];
                    for (let i = 0; i < views.LoadedViews.length; i++) {
                        const current = views.LoadedViews[i];
                        var model = this.models.getModel(current.UniqueID);

                        if (this.isModalView(current.UniqueID, views.ModalViews)) {
                            model.IsModalView = true;
                        }
                        this.createView(model, views.LoadedViews.map(c => c.UniqueID), orderViews);
                    }
                }
            }

            loadDeltas(response) {
                var viewDeltas = response.viewDeltas;
                this.removeViews(response);
                this.loadNewViews(viewDeltas.NewViews, viewDeltas.ModalViews);
                this.setFocus(viewDeltas.CurrentFocusedControl);
            }

            private setFocus(control) {
                if (control) {
                    const model = this.models.search(control);
                    if (model) {
                        model.applyMethod("focus");
                    }
                }
            }

            private loadNewViews(newViews: Array<string>, modalViews: Array<string>) {
                if (newViews) {
                    var orderViews = [];
                    var self = this;
                    newViews.forEach(item => {
                        var model = self.models.getModel(item);
                        if (self.isModalView(item, modalViews)) {
                            model.IsModalView = true;
                        }
                        if ($("#" + model.UniqueID).length == 0) {
                            this.createView(model, newViews, orderViews);
                        } else {
                            $("#" + model.UniqueID).parent().show();
                            $("#" + model.UniqueID).data("kendoWindow").toFront();
                            if (model.IsModalView) {
                                var n : any = $("#" + model.UniqueID).parent().css("z-index");
                                $(".k-overlay").css("z-index", n - 1);
                                $(".k-overlay").show();
                            }
                        }
                    });
                }
            }

            private isModalView(viewId: string, modalViews: Array<string>): boolean {
                return (modalViews && modalViews.indexOf(viewId) > -1);
            }

            private initializeCommand() {
                var self = this;
                this.registerCommand(Command.Commands.CloseCommand, command => self.closeHandler(command as Command.Close));
                this.registerCommand(Command.Commands.ClientCloseCommand, command => self.clientCloseHandler(command as Command.ClientClose));
                this.registerCommand(Command.Commands.SendCommand, command => self.sendHandler(command as Command.Send));
                this.registerCommand(Command.Commands.InputBoxActionCommand, command => self.inputBoxActionHandler(command as Command.InputBoxAction));
                this.registerCommand(Command.Commands.ModalCommand, command => self.modalHandler(command as Command.ModalCommand));
                this.registerCommand(Command.Commands.ModalActionCommand, command => self.modalActionHandler(command as Command.ModalAction));
                this.registerCommand(Command.Commands.KeyPressCommand, command => self.keyPressHandler(command as Command.KeyPress));
                // register view commands
                this.registerCommand(Command.Commands.MessageBox, command => self.messageBoxHandler(command as Command.MessageBox));
                this.registerCommand(Command.Commands.OpenView, command => self.openViewHandler(command as Command.OpenView));
                this.registerCommand(Command.Commands.CloseView, command => self.closeViewHandler(command as Command.CloseView));
                this.registerCommand(Command.Commands.InputBox, command => self.inputBoxHandler(command as Command.InputBox));
                if (this.onRegisterCommand) {
                    this.onRegisterCommand();
                }
            }

            private inputBoxHandler(command: Command.InputBox) {
                var modal = this.modalFactory.create(command.name);
                modal.show(command);
            }

            private messageBoxHandler(command: Command.MessageBox) {
                var modal = this.modalFactory.create("msg");
                modal.show(command);
            }

            private openViewHandler(command: Command.OpenView) {
                var model = this.models.getModel(command.UniqueID);
                this.createView(model);
            }

            private closeViewHandler(command: Command.CloseView) {
                this.deleteView(command.UniqueID);
                this.models.deleteCascade(command.UniqueID);
            }

            private closeHandler(command: Command.Close) {
                this.action.send(new Application.ActionModel(command.sender.name, command.action, command.sender.model));
            }

            private clientCloseHandler(command: Command.ClientClose) {
                this.action.send(new Application.ActionModel(command.controller, command.action, undefined, command.args));
            }

            private sendHandler(command: Command.Send) {
                this.action.send(new Application.ActionModel(command.sender.name, command.action, command.sender.model, command.args, command.callback));
            }

            private modalHandler(command: Command.ModalCommand) {
                const modal = this.modalFactory.create(command.modalName());
                if (modal) {
                    modal.show(command.parameters());
                }
            }

            private modalActionHandler(command: Command.ModalAction) {
                this.action.send(new Application.ActionModel("ResumeOperation", "ResumePendingOperation", null, {
                    dialogResult: command.dialogResult,
                    requestedInput: command.requestedInput
                }, undefined, new Server.RequestConfig(Contract.Server.RequestType.RawRequest)));
            }

            private inputBoxActionHandler(command: Command.InputBoxAction) {
                this.action.send(new Application.ActionModel("WebMapViewManager", "ResumePendingOperation", null, {
                    dialogResult: command.dialogResult,
                    requestedInput: command.requestedInput
                }, undefined, new Server.RequestConfig(Contract.Server.RequestType.RawRequest)));
            }

            private keyPressHandler(command: Command.KeyPress) {
                this.action.send(new Application.ActionModel(command.sender.name, command.action, command.sender.model, new Array<any>({ keyCode: command.key })));
            }
        }
    }
}
