/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        import ClientBehavior = Mobilize.Contract.Core.IClientBehavior;
        import Entity = Mobilize.Contract.Core.IEntity;
        import IModel = Mobilize.Contract.Core.IModel;
        import Order = Contract.Core.Order;

        export class HierarchyBehavior implements ClientBehavior {
            apply(model: IModel, root: Entity) {
                if (!model.isRoot() && !model.isPointer) {
                    const parent = root.getParentByModel(model);
                    /*tslint:disable:no-string-literal */
                    const name = model["aliasName"] ? model["aliasName"] : model.uniqueName();
                    parent.addReference(name, model);
                }
            }

            get Order(): Order {
                return Order.ORD;
            }
        }
    }
}
