/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Server {
        export class UrlResolver implements Contract.Server.IUrlResolver {
            resolveUrl(action: Contract.Application.IActionModel) {
                return this.getController(action) + this.getAction(action);
            }

            /*tslint:disable:no-string-literal */
            private getController(action: Contract.Application.IActionModel) {
                let controller = action.controller || "Home";
                if (action.item && action.item["area"]) {
                    controller = action.item["area"] + "/" + controller;
                }

                if (controller.length > 1 && controller[0] === "/") {
                    controller = controller.substring(1);
                }
                return controller;
            }

            private getAction(action: Contract.Application.IActionModel) {
                return `/${action.action}/`;
            }
        }
    }
}
