/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class ActionModel implements Contract.Application.IActionModel {
            private _controller: string;
            private _action: string;
            private _item: Contract.Core.IModel;
            private _parameters: any;
            private _callback: (response: any) => void;
            private _includeDirty: boolean;
            private _requestConfig: Contract.Server.IRequestConfig;

            constructor(controller: string = "", action: string = "", item: Contract.Core.IModel = null, parameters: any = null, callback: (response: any) => void = null, requestConfig?: Contract.Server.IRequestConfig) {
                this._controller = controller;
                this._action = action;
                this._item = item;
                this._parameters = parameters;
                this._callback = callback;
                this._requestConfig = (requestConfig) ? requestConfig : new Server.RequestConfig();
            }

            get controller() {
                return this._controller;
            }

            set controller(value: string) {
                this._controller = value;
            }

            get action() {
                return this._action;
            }

            set action(value: string) {
                this._action = value;
            }

            get item() {
                return this._item;
            }

            set item(value: Contract.Core.IModel) {
                this._item = value;
            }

            get parameters() {
                return this._parameters;
            }

            set parameters(value: any) {
                this._parameters = value;
            }

            get callback() {
                return this._callback;
            }

            set callback(value: (response: Contract.Server.IResponse) => void) {
                this._callback = value;
            }

            get includeDirty() {
                return this._includeDirty;
            }

            set includeDirty(value: boolean) {
                this._includeDirty = value;
            }

            get requestConfig() {
                return this._requestConfig;
            }

            set requestConfig(value: Contract.Server.IRequestConfig) {
                this._requestConfig = value;
            }
        }
    }
}
