/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Server {
        export class RequestConfig implements Contract.Server.IRequestConfig {
            requestType: Contract.Server.RequestType;
            includeDirty: boolean;
            contentType: string;

            // the default values for the request configuration are:
            // - requestType: RequestType.ModelRequest
            // - contentType: "application/json; charset=utf-8"
            // - includeDirty: true for Model request, false for RawRequest
            // use undefined if you want to call the constructor with a default value.
            constructor(_requestType?: Contract.Server.RequestType, _includeDirty?: boolean, _contentType?: string) {
                this.requestType = (_requestType !== undefined) ? _requestType : Contract.Server.RequestType.ModelRequest;
                this.contentType = (_contentType !== undefined) ? _contentType : Contract.Application.ContentType.application_json;
                if (_includeDirty !== undefined) {
                    this.includeDirty = _includeDirty;
                } else {
                    // using the already assigned value to requestType, in case the default was used
                    if (this.requestType === Contract.Server.RequestType.ModelRequest) {
                        this.includeDirty = true;
                    } else {
                        this.includeDirty = false;
                    }
                }
            }
        }
    }
}

