/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        import ExceptionHandler = Mobilize.System.Aop.ExceptionHandler;

        enum ActionState {
            Sending,
            Idle
        }

        export class ActionService implements Contract.Application.IAction {
            private pendingEvents: Array<PendingEvent>;
            private http: Contract.Server.IServer;
            private inject: Contract.Application.IInject;
            private changeBuffer: Contract.Core.IChangeBuffer;
            private urlResolver: Contract.Server.IUrlResolver;
            private event: Contract.Application.IEventAggregator;
            private state: ActionState;
            private requestBuilder: Contract.Server.IRequestBuilder;
            private commandGenerators: Array<() => Contract.Application.IRequestCommand>;

            constructor(inject: Contract.Application.IInject = null) {
                this.inject = inject || Inject.Instance;
                this.http = this.inject.resolve(Mobilize.Contract.Application.Constants.Server);
                this.urlResolver = this.inject.resolve(Mobilize.Contract.Application.Constants.UrlResolver);
                this.changeBuffer = this.inject.resolve(Mobilize.Contract.Application.Constants.NotifyBuffer);
                this.pendingEvents = new Array<PendingEvent>();
                this.event = this.inject.resolve(Contract.Application.Constants.EventAggregator);
                this.state = ActionState.Idle;
                this.requestBuilder = this.inject.resolve(Contract.Application.Constants.RequestBuilder);
                this.commandGenerators = [];
                if (this.event) {
                    this.event.subscribe(Contract.Application.Events.PreActionCommandGeneratorRegistration, (generator) => {
                        this.addCommandGenerator(generator);
                    });
                    this.event.subscribe(Contract.Application.Events.PreActionCommandGeneratorDeregistration, (generator) => {
                        this.removeCommandGenerator(generator);
                    });
                }
            }

            send(action: ActionModel) {
                const request = this.requestBuilder.create(action);
                if (action.requestConfig.includeDirty) {
                    request.dirty = this.changeBuffer.getChanges();
                }
                if (request.commands && this.state === ActionState.Idle) {
                    request.commands = this.buildCommandGenerators();
                }
                this.pendingEvents.push(new PendingEvent(action, request));
                this.sendActions();
            }

            public addCommandGenerator(commandGenerator: () => Contract.Application.IRequestCommand) {
                this.commandGenerators.push(commandGenerator);
            }

            public removeCommandGenerator(commandGenerator: () => Contract.Application.IRequestCommand) {
                this.commandGenerators = this.commandGenerators.filter(function (e) { return e != commandGenerator });
            }

            private processActionResponse(action: Contract.Application.IActionModel, response: Contract.Server.IResponse) {
                this.event.publish(Contract.Application.Events.ApplyDeltas, response);
                try {
                    if (action.callback) {
                        action.callback(response);
                    }
                }
                finally {
                    this.processPending();
                }
            }

            private processPending() {
                this.state = ActionState.Idle;
                if (this.pendingEvents.length > 0) {
                    this.sendActions();
                }
            }

            /*tslint:disable:no-unused-variable */
            private onException() {
                this.state = ActionState.Idle;
                this.processPending();
            }

            @ExceptionHandler("onException")
            private sendActions() {
                var self = this;
                if (this.state === ActionState.Idle) {
                    this.state = ActionState.Sending;
                    const event = this.pendingEvents.shift();
                    let data: any;
                    switch (event.action.requestConfig.contentType) {
                        case Contract.Application.ContentType.multipart_formdata:
                            data = event.request;
                            break;
                        case Contract.Application.ContentType.application_json:
                            data = JSON.stringify(event.request);
                            break;
                        default:
                            data = JSON.stringify(event.request);
                            break;
                    }
                    this.http.post(this.urlResolver.resolveUrl(event.action), data, (response) => { self.handleResponse(event.action, response); }, event.action.requestConfig);
                }
            }

            private handleResponse(action: Contract.Application.IActionModel, response: any) {
                if ((response === undefined) || response.status && response.status !== 200) {
                    //this.event.publish(Contract.Application.Events.Error, new System.NetworkException(response.responseText, response.status));
                    this.state = ActionState.Idle;
                    console.error(response.responseText);
                } else if (response.ErrorOcurred) {
                    this.event.publish(Contract.Application.Events.Error, new System.Exception(response.ExMessage + "\n" + response.ExStackTrace));
                    this.state = ActionState.Idle;
                    console.error(response.responseText);
                } else {
                    this.processActionResponse(action, new Server.Response(response));
                }
            }

            private buildCommandGenerators(): Array<Contract.Application.IRequestCommand> {
                if (this.commandGenerators.length == 0)
                    return [];
                return this.commandGenerators.map((c) => c()).filter(command => command != null)
            }
        }
    }
}
