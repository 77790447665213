/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class PendingEvent {
            private _action: Contract.Application.IActionModel;
            private _request: Contract.Server.IRequest;

            constructor(action: Contract.Application.IActionModel, request: Contract.Server.IRequest) {
                this._action = action;
                this._request = request;
            }

            get action(): Contract.Application.IActionModel {
                return this._action;
            }

            get request(): Contract.Server.IRequest {
                return this._request;
            }
        }
    }
}
