/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        export class TypeService implements Contract.Core.ITypeService {
            private aliases: any;
            private typesInfo: any;
            private inject: Contract.Application.IInject;
            private modelResolver: Contract.Core.IModelResolver;
            private behaviorEnabled: boolean;

            constructor(inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.modelResolver = this.inject.resolve(Contract.Application.Constants.ModelResolver);
                this.behaviorEnabled = false;
            }

            public getAliasName(model: string, alias: string) {
                try {
                    return this.aliases[model.toString()][alias][0];
                } catch(e){
                }
            }

            public getAlias(typeId: string) {
                return this.aliases[typeId];
            }

            public setAliases(aliases: any) {
                this.aliases = aliases;
            }

            public init(data: any) {
                this.enableAliasBehavior(data.DefaultsAndAlias);
                this.typesInfo = data.TypesInfo;
            }

            public setTypes(typesInfo: any) {
                this.typesInfo = typesInfo;
            }

            public getTypeInfo(typeId: string) {
                return this.typesInfo[typeId];
            }

            private enableAliasBehavior(aliasesInfo: any) {
                if (aliasesInfo && !this.behaviorEnabled) {
                    var firstKey = Object.keys(aliasesInfo)[0];
                    if (aliasesInfo[firstKey] !== undefined && Object.keys(aliasesInfo[firstKey]).length > 0) {
                        this.modelResolver.registerBehavior(new Core.DefaultValueBehavior());
                        this.modelResolver.registerBehavior(new Core.AliasBehavior());
                        this.aliases = aliasesInfo;
                        this.behaviorEnabled = true;
                    }
                }
            }
        }
    }
}
