/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
	export namespace Core {
		export class ModelResolver implements Contract.Core.IModelResolver {
			private behaviors: Contract.Application.IDictionary;
			private notifiers: Array<Contract.Core.IModelNotifier>;
			private _isSynchronizing: boolean;
			private buffer: Mobilize.Contract.Core.IBuffer;
			private modelFactory: Contract.Core.IModelFactory;

			constructor(private inject: Contract.Application.IInject = null) {
				this.inject = inject || Application.Inject.Instance;
				this.modelFactory = this.inject.resolve(Contract.Application.Constants.ModelFactory);
				this.behaviors = new Application.Dictionary();
				this.notifiers = new Array<Contract.Core.IModelNotifier>();
				this._isSynchronizing = false;
				this.PreProcessArray = (model) => { };
				this.PostProcessArray = (model) => {};
			}

			public init(buffer: Mobilize.Contract.Core.IBuffer) {
				this.buffer = buffer;
			}

			public registerNotifier(notifier: Contract.Core.IModelNotifier) {
				if (this.notifiers.some(item => item === notifier)) {
					this.notifiers[this.notifiers.indexOf(notifier)] = notifier;
				} else {
					this.notifiers.push(notifier);
				}
			}

			public registerBehavior(behavior: Contract.Core.IClientBehavior) {
				if (!this.behaviors.containsKey(behavior.Order.toString())) {
					this.behaviors.add(behavior.Order.toString(), []);
				}
				this.addBehavior(behavior);
			}

			public process(): void {
				var behaviors = this.getBehaviors();
				this.applyBehaviors(behaviors, this.buffer.toArray());
				this.resolveArrayDeltas(this.buffer.toArray().filter(model => model["@arr"]));
			}

			public attachModels(modelDeltas: Array<Contract.Core.IModel>) {
				this._isSynchronizing = true;

				var newModels = this.propagateDeltas(modelDeltas);
				var stubModels = this.createMissingHierarchy(newModels);
				newModels = newModels.concat(stubModels);

				const behaviors = this.getBehaviors();
				this.applyBehaviors(behaviors, newModels);
				this.resolveArrayDeltas(modelDeltas.filter(model => {
					return model.UniqueID.length > 0 && this.buffer.getModel(model.UniqueID)["@arr"];
				}));

				this._isSynchronizing = false;
			}

			private resolveValueTypeArray(models: any, Observable: Contract.Core.IModel): number {
				let index;
				for (index = 0; index < models.length; index++) {
					Observable.addValueArray(Observable, models[index], index);
				}
				return index;
			}

			private resolveStateObjectAndSurrogateArray(models: any, Observable: Contract.Core.IModel): number {
				let index;
				for (index = 0; index < models.Count; index++) {
					const element = this.buffer.getModel(models.uids[index]);
					//if element is not null, then this means that it is an ObservableObject
					if (element !== null) {
						Observable.addValueArray(Observable, element, index);
					} else {
						//If element is null, then this is maybe an object that was marked as 
						//IDependentModel at the server side, so the object is not passed to the client side.
						Observable.addValueArray(Observable, this.modelFactory.create({ UniqueID: "" }), index);
					}
				}
				return index;
			}

			private resolveObjectArray(models: any, Observable: Contract.Core.IModel): number {
				let index;
				for (index = 0; index < models.Count; index++) {
					const element = this.buffer.getModel(models.uids[index]);
					//if element is not null, then this means that it is an ObservableObject
					if (element !== null) {
						Observable.addValueArray(Observable, element, index);
					} else {
						//This is a Value Type
						Observable.addValueArray(Observable, models.uids[index], index);
					}
				}
				return index;
			}

			private resolveArrayDeltas(arrayDeltas: Array<Contract.Core.IModel>) {
				for (let iter = 0; iter < arrayDeltas.length; iter++) {
					this.PreProcessArray(arrayDeltas[iter]);
					let delta = arrayDeltas[iter], arrayObservable = this.buffer.getModel(delta.UniqueID);
					if (!arrayObservable.isRoot() && !arrayObservable.isPointer) {
						let index;
						switch (delta["ltype"]) {
							/* ValueTypes array*/
							case 1:
								index = this.resolveValueTypeArray(delta["uids"], arrayObservable);
								break;
							/* IStateObject and Surrogate array */
							case 2:
							case 3:
								index = this.resolveStateObjectAndSurrogateArray(delta, arrayObservable);
								break;
							/* Object array */
							case 4:
								index = this.resolveObjectArray(delta, arrayObservable);
								break;
						}
						/* Remove items after the length of new Array. */
						if (delta["uids"].length < arrayObservable["length"]) {
							arrayObservable.removeValueAray(arrayObservable, index, arrayObservable["length"] - delta["uids"].length);
						}
					}
                    this.PostProcessArray(delta);
                    if (!arrayObservable.hasCircularReference()) {
                        arrayObservable.fireChange();
                    }
				}
			}

			protected PreProcessArray : (model : Contract.Core.IModel) => void ;

			protected PostProcessArray : (Model: Contract.Core.IModel) => void ;


			public removeModels(models: Array<Contract.Core.IModel>) {
				this._isSynchronizing = true;
				models.forEach(model => {
					this.detachModel(model);
					this.buffer.deleteCascade(model.UniqueID);
				});
				this._isSynchronizing = false;
			}

			public switchIds(ids: Array<Array<string>>) {
				ids.forEach(item => this.buffer.switchIds(item[0], item[1]));
			}

			public isCoreSynchronizing() {
				return this._isSynchronizing;
			}

			private detachModel(model: Contract.Core.IModel) {
				const parent = this.buffer.getParentByModel(model);
				if (parent) {
					parent.removeModel(model);
				}
			}

			private addBehavior(behavior: Contract.Core.IClientBehavior) {
				const items: Array<Contract.Core.IClientBehavior> = this.behaviors.value(behavior.Order.toString());
				if (items.some(item => item === behavior)) {
					items[items.indexOf(behavior)] = behavior;
				} else {
					items.push(behavior);
				}
			}

			private propagateDeltas(modelDeltas: Array<Contract.Core.IModel>): Array<Contract.Core.IModel> {
				const newsModels = [];
				modelDeltas.forEach(item => {
					// make sure the item has a UniqueID, otherwise the update process crashes
					if (item.UniqueID.length > 0) {
						if (this.buffer.exists(item)) {
                            this.updateModel(item);
                            if (item.isPointer)
                            {
                                newsModels.push(item);
                            }
						} else {
							newsModels.push(item);
							this.buffer.add(item);
						}
					}
				});
				return newsModels;
			}
			
			private attachIsSynchronizingClient(model: Contract.Core.IModel) {
				var self = this;
				const item = this.buffer.getModel(model.UniqueID);
				item.isCoreSynchronizing = () => self.isCoreSynchronizing();
			}

			private updateModel(item: Contract.Core.IModel) {
				const model = this.buffer.getModel(item.UniqueID);
				model.updateModel(item);
			}

			private getBehaviors() {
				return {
					pre: this.behaviors.value(Contract.Core.Order.PRE.toString()) || [],
					ord: this.behaviors.value(Contract.Core.Order.ORD.toString()) || [],
					evt: this.notifiers,
					post: this.behaviors.value(Contract.Core.Order.POST.toString()) || []
				};
			}

			private applyBehaviors(behaviors: any, models: Array<Contract.Core.IModel>) {
				models.forEach((model) => this.attachIsSynchronizingClient(model));

				behaviors.pre.forEach((behavior) => {
					models.forEach((model) => this.tryApplyBehaviors(behavior, model));
				});
				behaviors.ord.forEach((behavior) => {
					models.forEach((model) => this.tryApplyBehaviors(behavior, model));
				});
				behaviors.post.forEach((behavior) => {
					models.forEach((model) => this.tryApplyBehaviors(behavior, model));
				});
				behaviors.evt.forEach((notify) => {
					models.forEach((model) => this.tryApplyBehaviors(notify, model));
				});
			}

			private tryApplyBehaviors(behavior: any, model: Contract.Core.IModel) {
				try {
					behavior.apply(this.buffer.getModel(model.UniqueID), this.buffer);
				} catch (e) {
					console.error(`Error procesing an behavior ${e}${e.stack}`);
				}
			}

			private createMissingHierarchy(models: Array<Contract.Core.IModel>): Array<Contract.Core.IModel> {
				var stubModels = [];
				for (let i = 0; i < models.length; i++) {
					let model = models[i];
					var hierarchy = this.createMissingParent(model, this.buffer);
					if (hierarchy.length > 0) {
						stubModels = stubModels.concat(hierarchy);
					}
				}
				return stubModels;
			}

			private createMissingParent(model: Contract.Core.IModel, buffer: Contract.Core.IBuffer): Array<Contract.Core.IModel> {
				var stubModels = [];
				if (!model.isRoot()) {
					const parent = buffer.getParentByModel(model);
					if (!parent) {
						var parentData = { UniqueID: model.parentName() };
						// check if the name is a number, which means the parent is an array
						if (!isNaN(parseFloat(model.uniqueName()))) {
							parentData["@arr"] = 1;
						}
						var parentStub = this.modelFactory.create(parentData);
						stubModels.push(parentStub);
						buffer.add(parentStub);

						// make sure the rest of the hierarchy is populated
						var hierarchy = this.createMissingParent(parentStub, buffer);
						if (hierarchy.length > 0) {
							stubModels = stubModels.concat(hierarchy);
						}
					}
				}
				return stubModels;
			}

		    public replaceBehavior(oldBehaviorName: string, behavior: Mobilize.Contract.Core.IClientBehavior) {
                const items: Array<Contract.Core.IClientBehavior> = this.behaviors.value(behavior.Order.toString()) || [];
                if (items.length > 0) {
                    var oldBehavior = items.filter(item => (<any>item.constructor).name === oldBehaviorName);
                    if (oldBehavior != null){ //some(item => item.constructor.name === oldBehaviorName)) {
                        items[items.indexOf(oldBehavior[0])] = behavior;
                    } else {
                        this.registerBehavior(behavior);
                    }
                } else {
                    this.registerBehavior(behavior);
                }
		    }
		}
	}
}
