/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace System.Aop {
        export function ExceptionHandler(onException?: string) {
            return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
                var originalMethod = descriptor.value;
                descriptor.value = function(...args: any[]) {
                    const event = Application.Inject.Instance.resolve(Contract.Application.Constants.EventAggregator);
                    try {
                        return originalMethod.apply(this, args);
                    } catch (e) {
                        console.error(e);
                        event.publish(Contract.Application.Events.Error, e);
                        if (this[onException]) {
                            this[onException]();
                        }
                    }
                };

                return descriptor;
            };
        }
    }
}
