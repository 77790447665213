/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class EventAggregator implements Contract.Application.IEventAggregator {
            private events;

            constructor() {
                this.events = {};
            }

            publish(event: string, data?: any) {
                if (this.events[event]) {
                    for (let subscriber of this.events[event]) {
                        subscriber(data);
                    }
                }
            }

            subscribe(event: string, lambda: (data: any) => void) {
                if (!this.events[event]) {
                    this.events[event] = [];
                }

                this.events[event].push(lambda);
            }
        }
    }
}
