/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class Commands {
            static get CloseCommand() {
                return "CloseCommand";
            }

            static get ClientCloseCommand() {
                return "ClientCloseCommand";
            }

            static get KeyPressCommand() {
                return "KeyPressCommand";
            }

            static get InputBoxActionCommand() {
                return "InputBoxActionCommand";
            }

            static get ModalCommand() {
                return "ModalCommand";
            }

            static get ModalActionCommand() {
                return "ModalActionCommand";
            }

            static get SendCommand() {
                return "SendCommand";
            }

            static get LoadingCommand() {
                return "LoadingCommand";
            }

            static get MessageBox() {
                return "msg";
            }

            static get OpenView() {
                return "OpenView";
            }

            static get CloseView() {
                return "CloseView";
            }

            static get InputBox() {
                return "inputbox";
            }
        }
    }
}
