/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Contract.Server {
        export interface IServer {
            get(url: string, params: Array<any>, callback: (response: any) => void);

            post(url: string, data: any, callback: (response: any) => void, config?: IRequestConfig);
        }

        export interface IUrlResolver {
            resolveUrl(action: Contract.Application.IActionModel);
        }

        export interface IResponse {
            views: any;
            models: any;
            viewDeltas: any;
            modelDeltas: any;
            modelDeltaTypes: any;
            switchIds: any;
            removeIds: any;
        }

        export interface IRawResponse {
            V: any;
            M: any;
            MT: any;
            VD: any;
            MD: any;
            MDT: any;
            SW: any;
            RM: any;
        }

        export interface IRequest {
            dirty: Array<any>;
            vm: string;
            parameters: Array<any>;
        }

        export interface IRequestConfig {
            requestType: RequestType;
            contentType: string;
            includeDirty: boolean;
        }

        export interface IRequestBuilder {
            create(action: Mobilize.Application.ActionModel): any;
        }

        export enum RequestType {
            RawRequest,
            ModelRequest
        }
    }
}
