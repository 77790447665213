/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class DeltaHandler implements Contract.Application.IDeltaHandler {
            private preWorkers: Array<Contract.Application.IDeltaWorker>;
            private workers: Array<Contract.Application.IDeltaWorker>;
            private postWorkers: Array<Contract.Application.IDeltaWorker>;
            private event: Contract.Application.IEventAggregator;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject ? inject : Application.Inject.Instance;
                this.event = this.inject.resolve(Contract.Application.Constants.EventAggregator);
                this.preWorkers = [];
                this.workers = [];
                this.postWorkers = [];
            }

            public registerWorker(worker: Contract.Application.IDeltaWorker) {
                if (worker) {
                    let selectedArray: Array<Contract.Application.IDeltaWorker>;

                    switch (worker.Order) {
                        case Contract.Core.Order.PRE:
                            selectedArray = this.preWorkers;
                            break;
                        case Contract.Core.Order.ORD:
                            selectedArray = this.workers;
                            break;
                        case Contract.Core.Order.POST:
                            selectedArray = this.postWorkers;
                            break;
                    }

                    if (selectedArray.indexOf(worker) === -1) {
                        selectedArray.push(worker);
                    }
                }
            }

            public executeWorkers(response: Contract.Server.IResponse) {
                this.preWorkers.forEach((worker) => {
                    worker.process(response);
                });

                this.workers.forEach((worker) => {
                    worker.process(response);
                });

                this.postWorkers.forEach((worker) => {
                    worker.process(response);
                });

                // execute the registered work after the deltas have been processed
                this.event.publish(Contract.Application.Events.ExecWorkQueue);
            }
        }
    }
}
