/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace System {
        export class NetworkException implements Contract.System.INetworkException {
            private _message: string;
            private _code: Contract.System.ErrorCode;

            constructor(message: string, code: Contract.System.ErrorCode) {
                this._message = message;
                this._code = code;
            }

            get message(): string {
                return this._message;
            }

            get code(): Contract.System.ErrorCode {
                return this._code;
            }
        }
    }
}
