/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Contract.Core {
        export enum Order {
            PRE = 1,
            ORD = 2,
            POST = 3
        }

        export interface IChangeBuffer {
            init(entity: IEntity);
            getChanges(): any;
        }

        export interface IModelNotifier {
            apply(model: IModel);
        }

        export interface IModelFactory {
            create(model: any): IModel;
        }

        export interface IClientBehavior {
            Order: Order;
            apply(model: IModel, root: IEntity);
        }

        export interface IEntity {
            getModel(key: string): IModel;
            getParentByKey(key: string): IModel;
            getParentByModel(model: IModel): IModel;
            replace(model: IModel): void;
            exists(model: IModel): boolean;
            deleteCascade(key: string);
            search(name: string): IModel;
        }

        export interface IBuffer extends IEntity {
            add(model: IModel);
            apply(fn: (model: IModel) => any);
            addRange(models: Array<IModel>);
            switchIds(oldUniqueId: string, newUniqueId: string);
            toArray(): Array<IModel>;
        }

        export interface IModelResolver {
            isCoreSynchronizing: () => boolean;
            init(buffer: IBuffer);
            process(): void;
            registerNotifier(notify: IModelNotifier);
            registerBehavior(behavior: IClientBehavior);
            replaceBehavior(oldBehaviorName: string, behavior: IClientBehavior);
            attachModels(modelDeltas: Array<IModel>);
            removeModels(models: Array<Contract.Core.IModel>);
            switchIds(ids: Array<Array<string>>);
        }

        export interface IModel {
            UniqueID: string;
            IsTempModel: boolean;
            IsModalView: boolean;
            childrenToNotify: Array<IModel>;
            isPointer: boolean;
            isArray: boolean;
            getPos(key?: string): number;
            uniqueName(): string;
            parentName(): string;
            isRoot(): boolean;
            isCoreSynchronizing();
            updateModel(item: IModel);
            applyOnProperties(item: IModel, fn: (property: any) => void);
            fireChange();
            addReference(property: string, item: IModel);
            addValue(property: string, value: any);
            removeModel(item: IModel);
            applyMethod(method: string);
            addPointerId(key: string, pointerId: string);
            onPropertyChange: (property: string, oldValue: any, newValue: any) => void;
            addValueArray(array: any, item: any, position: number);
            removeValueAray(array: any, index: number, count: number);
            hasCircularReference(): boolean;
        }

        export interface IDirty {
            toReduce(): any;
        }

        export interface ITypeService {
            init(data: any): void;
            setAliases(aliases : any): void;
            getAliasName(model: string, alias: string): string;
            getAlias(typeId: string): any;
            setTypes(typesInfo: any);
            getTypeInfo(typeId: string);
        }
    }
}
