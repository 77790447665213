/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        export class ModalFactory implements Contract.Ui.IModalFactory {
            private factories: Contract.Application.IDictionary;

            constructor() {
                this.factories = new Application.Dictionary();
            }

            create(type: string): Mobilize.Contract.Ui.IModal {
                const modal = this.factories.value(type);
                if (modal) {
                    return modal;
                }
                return null;
            }

            register(modal: Mobilize.Contract.Ui.IModal) {
                this.factories.add(modal.name(), modal);
            }
        }
    }
}
