/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        export class Invoker implements Contract.Ui.IInvoker {
            private receivers: Array<Contract.Ui.IReceiver>;

            constructor() {
                this.receivers = [];
            }

            invoke(command: Contract.Ui.ICommand) {
                this.receivers.forEach(c => {
                    if (c.hasCommand(command)) {
                        c.execute(command);
                    }
                });
            }

            register(receiver: Contract.Ui.IReceiver) {
                this.receivers.push(receiver);
            }
        }
    }
}
