/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class ModalAction implements Contract.Ui.ICommand {
            private _dialogResult: string;
            private _requestedInput: string;

            constructor(dialogResult: string = null, requestedInput: string = null) {
                this._dialogResult = dialogResult;
                this._requestedInput = requestedInput;
            }

            setArgs(dialogResult: string, requestedInput: string) {
                this._dialogResult = dialogResult;
                this._requestedInput = requestedInput;
            }

            get name(): string {
                return Commands.ModalActionCommand;
            }

            get dialogResult(): string {
                return this._dialogResult;
            }

            get requestedInput() {
                return this._requestedInput;
            }
        }
    }
}
