/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        export class ChangeNotifier implements Mobilize.Contract.Core.IModelNotifier {
            private event: Contract.Application.IEventAggregator;
            private inject: Contract.Application.IInject;

            constructor(inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.event = this.inject.resolve(Contract.Application.Constants.EventAggregator);
            }

            apply(model: Mobilize.Contract.Core.IModel) {
                /*tslint:disable:no-string-literal */
                model["__onchange"] = (property) => {
                    if (!model.isCoreSynchronizing()) {
                        this.event.publish(Contract.Application.Events.Change, { model, property });
                    }
                };
            }
        }
    }
}
