/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        import ClientBehavior = Mobilize.Contract.Core.IClientBehavior;
        import Entity = Mobilize.Contract.Core.IEntity;
        import IModel = Mobilize.Contract.Core.IModel;
        import Order = Contract.Core.Order;

        export class AliasBehavior implements ClientBehavior {
            aliasService: Contract.Core.ITypeService;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.aliasService = this.inject.resolve(Contract.Application.Constants.TypeService);
            }


            apply(model: IModel, root: Entity) {
                if (!model.isRoot()) {
                    const name = model.uniqueName();
                    if (name.length > 0) {
                        const parent = root.getParentByModel(model);
                        // if the parent is an array the model's name is a number (the position in the array) which doesn't have an alias
                        if (!parent.isArray) {
                            let setTypeResult = true;
                            if (parent["@k"] == undefined) {
                                setTypeResult = this.setType(parent, root);
                            }

                            if (setTypeResult) {
                                let aliasRealName = this.aliasService.getAliasName(parent["@k"], name);
                                if (aliasRealName == "_items") {
                                    aliasRealName = "Items";
                                }
                                /*tslint:disable:no-string-literal */
                                model["aliasName"] = aliasRealName;
                            }
                        }
                    }
                }
            }
            
            private setType(parent: any, root: Entity): boolean {
                if (parent.UniqueID.indexOf(Model.collectionItemPrefix) == 0) {
                    return false;
                } else if (parent.isRoot() && parent.UniqueID.indexOf(Model.collectionItemPrefix) === -1) {
                    return true;
                }
                else {
                    let parent_2 = root.getParentByModel(parent);
                    let setTypeResult = true;
                    if (parent_2["@k"] == undefined ) {
                        setTypeResult = this.setType(root.getParentByModel(parent_2), root);
                    }
                    if (setTypeResult == true) {
                        try {
                            let parent_uniqueName = parent.uniqueName();
                            let parent_2_type = parent_2["@k"];
                            let parent_2_typeInfo = this.aliasService.getAlias(parent_2_type.toString());
                            let parent_Type = parent_2_typeInfo[parent_uniqueName][2];
                            parent["@k"] = parent_Type;
                        } catch (e){
                        }
                    }
                    return setTypeResult;
                }
            }

            get Order(): Order {
                return Order.PRE;
            }
        }
    }
}

