/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class Close implements Mobilize.Contract.Ui.ICommand {
            private _sender: Mobilize.Contract.Ui.IView;
            private _action: any;

            constructor(sender: Mobilize.Contract.Ui.IView, action: string) {
                this._sender = sender;
                this._action = action;
            }

            get sender(): Mobilize.Contract.Ui.IView {
                return this._sender;
            }

            get action(): any {
                return this._action;
            }

            get name(): string {
                return Commands.CloseCommand;
            }
        }
    }
}
