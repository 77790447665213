/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class Dictionary implements Contract.Application.IDictionary {
            _keys: Array<string>;
            _values: Array<any>;

            constructor() {
                this._keys = new Array<string>();
                this._values = new Array<any>();
            }

            add(key: string, value: any) {
                // remove if the key already exists to avoid duplicates in the arrays of keys and values
                if (this[key]) {
                    this.remove(key);
                }
                this[key] = value;
                this._keys.push(key);
                this._values.push(value);
            }

            remove(key: string) {
                const index = this._keys.indexOf(key, 0);
                this._keys.splice(index, 1);
                this._values.splice(index, 1);
                delete this[key];
            }

            keys(): string[] {
                return this._keys;
            }

            values(): any[] {
                return this._values;
            }

            value(key: string) {
                return this[key];
            }

            containsKey(key: string) {
                if (typeof this[key] === "undefined") {
                    return false;
                }

                return true;
            }

            toLookup(): Contract.Application.IDictionary {
                return this;
            }

            get length() {
                return this._values.length;
            }

            replace(key: string, value: any) {
                if (this.containsKey(key)) {
                    this[key] = value;
                }
            }
        }
    }
}
