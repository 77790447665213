/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Server {
        export class RequestBuilder implements Contract.Server.IRequestBuilder {
            private library: Contract.Application.ILibrary;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.library = this.inject.resolve(Contract.Application.Constants.Library);
            }

            public create(action: Mobilize.Application.ActionModel): any {
                let request: any;
                switch (action.requestConfig.requestType) {
                    case Contract.Server.RequestType.ModelRequest:
                        request = new Request();
                        request.vm = action.item ? action.item.UniqueID : "";
                        this.library.dom.extend(request, action.parameters);
                        break;
                    case Contract.Server.RequestType.RawRequest:
                        request = action.parameters;
                        break;
                    default:
                        request = new Request();
                        request.parameters = action.parameters;
                        break;
                }
                return request;
            }
        }
    }
}
