/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export abstract class ModalCommand implements Contract.Ui.ICommand {
            abstract modalName(): string;

            abstract parameters(): any;

            get name(): string {
                return Commands.ModalCommand;
            }
        }
    }
}
