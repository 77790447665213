/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        export class PointerBehavior implements Mobilize.Contract.Core.IClientBehavior {
            /*tslint:disable:no-string-literal */
            apply(model: Contract.Core.IModel, root: Mobilize.Contract.Core.IEntity) {
                if (model.isPointer) {
                    // the original json object for a pointer has an structure like:
                    // {@k:2, p["->property#object1#2", "proper2#panel1#2"]}
                    // or
                    // {@k:2, v["->value#object#2", "MyString"]}
                    // and gets transformed (in the Model constructor) into something like:
                    // {@k:2, UniqueID:"property#object1#2", p:"proper2#panel1#2"]}
                    // or
                    // {@k:2, UniqueID:"value#object#2", v:"MyString"]}
                    var value: any;
                    if (model["p"]) {
                        value = model["p"];
                    } else if (model["v"]) {
                        value = model["v"];
                    }

                    const parent = root.getParentByModel(model);

                    if (model["p"]) {
                        const pointedObject = root.getModel(value);
                        if (pointedObject) {
                            pointedObject.addPointerId(parent.UniqueID, model.UniqueID);
                            parent.addReference(model.uniqueName(), pointedObject);
                        } else {
                            //console.error("PointerBehavior: Unable to find model '" + value + "' in the model buffer, pointed by model '" + model.UniqueID + "'");
                        }
                    } else if (model["v"]) {
                        parent.addValue(model.uniqueName(), value);
                    }
                }
            }

            get Order(): Contract.Core.Order {
                return Contract.Core.Order.ORD;
            }
        }
    }
}
