/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application.DeltaWorker {
        export class ViewDeltaWorker implements Contract.Application.IDeltaWorker {
            private viewManager: Contract.Ui.IViewManager;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.viewManager = this.inject.resolve(Contract.Application.Constants.ViewManager);
            }

            process(response: Contract.Server.IResponse) {
                if (response.viewDeltas) {
                    if (response.viewDeltas.LoadedViews) {
                        this.viewManager.loadFirstViews(response.viewDeltas);
                    } else {
                        this.viewManager.loadDeltas(response.viewDeltas);
                    }
                }
            }

            get Order(): Contract.Core.Order {
                return Contract.Core.Order.ORD;
            }
        }
    }
}
