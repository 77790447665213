/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        import ClientBehavior = Mobilize.Contract.Core.IClientBehavior;
        import IModel = Mobilize.Contract.Core.IModel;
        import Entity = Mobilize.Contract.Core.IEntity;
        import Order = Contract.Core.Order;

        export class PropagateChangeIdBehavior implements ClientBehavior {
            apply(model: IModel, root: Entity) {
                if (!model.isRoot()) {
                    const parent = root.getParentByModel(model);
                    (parent as Model).__subscribe(model as Model);
                }
            }

            get Order(): Order {
                return Order.ORD;
            }
        }
    }
}
