/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application {
        export class Inject implements Contract.Application.IInject {
            private static _instance: Contract.Application.IInject;

            private dictionary: Contract.Application.IDictionary;

            constructor(dictionary: Contract.Application.IDictionary = null) {
                this.dictionary = dictionary || new Dictionary();
            }

            register(name: string, _constructor: any, resolve: Contract.Application.Resolve) {
                if (this.dictionary.containsKey(name)) {
                    this.dictionary.remove(name);
                }
                this.dictionary.add(name, new InjectMember(_constructor, resolve));
            }

            resolve(name: string) {
                if (this.dictionary.containsKey(name)) {
                    const item = this.dictionary.value(name);
                    return item.getObject();
                }
                return null;
            }

            public static get Instance() {
                if (this._instance === undefined) {
                    this._instance = new Inject();
                }
                return this._instance;
            }
        }
    }
}
