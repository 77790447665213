/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui.Command {
        export class KeyPress implements Contract.Ui.ICommand {
            private _sender: Contract.Ui.IView;
            private _key: string;
            private _action: string;

            constructor(sender: Contract.Ui.IView, action: string, key: string) {
                this._sender = sender;
                this._key = key;
                this._action = action;
            }

            get name(): string {
                return Commands.KeyPressCommand;
            }

            get sender(): Contract.Ui.IView {
                return this._sender;
            }

            get key(): string {
                return this.key;
            }

            get action() {
                return this._action;
            }
        }
    }
}
