/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        export abstract class Modal implements Contract.Ui.IModal {

            public onLoad: () => void;
            protected library: Contract.Application.ILibrary;
            private invoker: Contract.Ui.IInvoker;

            constructor(inject: Contract.Application.IInject = null) {
                const _inject = inject || Application.Inject.Instance;
                this.invoker = _inject.resolve(Contract.Application.Constants.Invoker);
                this.library = _inject.resolve(Contract.Application.Constants.Library);
            }

            abstract name(): string;

            invoke(command: Contract.Ui.ICommand) {
                this.invoker.invoke(command);
            }

            executeLoad() {
                if (this.onLoad) {
                    this.onLoad();
                }
            }

            abstract show(params: any);
        }
    }
}
