/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Application.DeltaWorker {
        export class CommandsWorker implements Contract.Application.ICommandWorker {
            private invoker: Contract.Ui.IInvoker;
            private commandFactory: Contract.Ui.ICommandFactory;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.invoker = this.inject.resolve(Contract.Application.Constants.Invoker);
                this.commandFactory = this.inject.resolve(Contract.Application.Constants.CommandFactory);
            }

            process(response: Contract.Server.IResponse) {
                if (response.viewDeltas && response.viewDeltas.Commands) {
                    response.viewDeltas.Commands.forEach(c => {
                        this.invoker.invoke(this.commandFactory.create(c));
                    });
                }
            }

            get Order(): Contract.Core.Order {
                return Contract.Core.Order.ORD;
            }
        }
    }
}
