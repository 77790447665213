/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Server {
        export class Response implements Contract.Server.IResponse {
            V: any;
            M: any;
            MT: any;
            VD: any;
            MD: any;
            MDT: any;
            SW: any;
            RM: any;

            constructor(rawResponse: Mobilize.Contract.Server.IRawResponse) {
                this.addProperties(rawResponse);
                this.checkModelTypes(rawResponse);
            }

            get views() {
                return this.VD;
            }

            get models() {
                return this.MD;
            }

            get viewDeltas() {
                return this.V ? this.V : this.VD;
            }

            get modelDeltas() {
                return this.M ? this.M : this.MD;
            }

            get modelDeltaTypes() {
                return this.MT ? this.MT : this.MDT;
            }

            get switchIds() {
                return this.SW;
            }

            get removeIds() {
                return this.RM;
            }

            private addProperties(rawResponse: Mobilize.Contract.Server.IRawResponse) {
                for (let key in rawResponse) {
                    if (rawResponse.hasOwnProperty(key)) {
                        this[key] = rawResponse[key];
                    }
                }
            }

            /// check if the association of model types needs to be done
            private checkModelTypes(rawResponse: Mobilize.Contract.Server.IRawResponse) {
                if (rawResponse.MT) {
                    this.associateModelTypes(rawResponse.M, rawResponse.MT);
                }
                if (rawResponse.MDT) {
                    this.associateModelTypes(rawResponse.MD, rawResponse.MDT);
                }
            }

            private associateModelTypes(models: any[], modelTypes: number[]) {
                if (modelTypes && modelTypes.length) {
                    for (let i = 0; i < modelTypes.length; i++) {
                        this.mergeType(models[i], modelTypes[i]);
                    }
                }
            }

            /// don't set model type if the model already has one
            private mergeType(model, modelType) {
                if (model && modelType && !model["@k"]) {
                    model["@k"] = modelType;
                }
            }
        }
    }
}
