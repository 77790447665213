/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        export class Model implements Contract.Core.IModel {

            public static separator = "#";
            public static collectionItemPrefix = "KI";
            public childrenToNotify: Array<Model>;
            public onPropertyChange: (property: string, oldValue: any, newValue: any) => void;
            protected onModelChange: () => void;
            private _uniqueID: string;
            private _pointers: any;
            private _internalProperties = ["_internalProperties", "childrenToNotify", "onModelChange", "_isTempModel", "_pointers"];
            private _isTempModel: boolean;
            private _isModalView: boolean;

            notifiercount(): number {
                return this.childrenToNotify.length;
            }

            get UniqueID(): string {
                return this._uniqueID;
            }

            set UniqueID(value: string) {
                this._uniqueID = value;
                this.childrenToNotify.forEach(c => c.__notify("UniqueID", value));
            }

            get IsTempModel(): boolean {
                return this._isTempModel;
            }

            set IsTempModel(value: boolean) {
                this._isTempModel = value;
            }


            get IsModalView(): boolean {
                return this._isModalView;
            }

            set IsModalView(value: boolean) {
                this._isModalView = value;
            }

            get isPointer(): boolean {
                /*tslint:disable:no-string-literal */
                return this["@k"] === 2 && (this["p"] || this["v"]);
            }

            __notify(property, value) {
                if (property === "UniqueID") {
                    this.UniqueID = this.uniqueName() + Model.separator + value;
                } else {
                    this[property] = value;
                }
            }

            __subscribe(model: Model) {
                this.childrenToNotify.push(model);
            }

            constructor(uniqueId: string = "", obj: any = null) {
                this._uniqueID = uniqueId;
                this._isTempModel = false;
                this._isModalView = false;
                this._pointers = {};
                this.addProperties(obj);
                if (this.isArray) {
                    this.addArrayMethods(this);
                }
                if (this.isPointer) {
                    this.addPointerProperties(this);
                }
                this.childrenToNotify = new Array();
            }

            addReference(property: string, item: Contract.Core.IModel) {
                if (this.isArray) {
                    this.addOnArray(this, item);
                } else {
                    this[property] = item;
                }
                this.fireChange();
            }

            addValue(property: string, value: any) {
                this[property] = value;
                this.fireChange();
            }

            uniqueName(): string {
                const name = this.UniqueID.split(Model.separator)[0];
                return name === "_items" ? "Items" : name;
            }

            parentName(): string {
                return this.UniqueID.substr(this.UniqueID.indexOf(Model.separator) + Model.separator.length);
            }

            isRoot(): boolean {
                return this.UniqueID.indexOf(Model.separator) === -1;
            }

            parentNameKey(key: string): string {
                return key.substr(key.indexOf(Model.separator) + 1);
            }

            isCoreSynchronizing(): boolean {
                return false;
            }

            updateModel(item: Contract.Core.IModel) {
                this.applyOnProperties(item, (prop) => {
                    if (this[prop] !== item[prop]) {
                        let oldValue = this[prop];
                        this[prop] = item[prop];
                        if (this.onPropertyChange != null) {
                            this.onPropertyChange(prop, oldValue, this[prop]);
                        }
                    }
                });
                this.fireChange();
            }

            applyOnProperties(item: Contract.Core.IModel, fn: (property: any) => void) {
                for (let prop in item) {
                    // skip the internal properties that shouldn't be updated when applying the deltas
                    if (item.hasOwnProperty(prop) && this._internalProperties.indexOf(prop) === -1) {
                        fn.call(this, prop);
                    }
                }
            }

            removeModel(item: Contract.Core.IModel) {
                var removed: boolean = false;
                if (this.isArray) {
                    removed = this.removeOnArray(item as Model);
                } else {
                    if (this[item.uniqueName()]) {
                        delete this[item.uniqueName()];
                        removed = true;
                    }
                }

                // processing only necessary if an item was removed
                if (removed) {
                    const itemIndex = this.childrenToNotify.indexOf(item as Model);
                    if (itemIndex > -1) {
                        this.childrenToNotify.splice(itemIndex, 1);
                    }
                    this.fireChange();
                }
            }

            fireChange() {
                if (this.onModelChange) {
                    this.onModelChange();
                }
            }

            applyMethod(method: string) {
                if (this[method]) {
                    this[method]();
                }
            }

            hasCircularReference(): boolean {
                if (!this.isArray)
                {
                    return false;
                }

                let observable: any = this;
                for (var i = 0; i < observable.length; i++) {
                    let isCircularReference = observable.UniqueID.indexOf(observable[i].UniqueID) > 0;
                    if (isCircularReference)
                    {
                        // Example:
                        // Given an array the following UniqueID:   items#prop1#KIABC
                        //            and an element of UniqueID:               KIABC
                        // Please notice that KIABC is within the UniqueID of the array, this is a circular reference.
                        return true;
                    }
                }

                return false;
            }

            addPointerId(key: string, pointerId: string) {
                this._pointers[key] = pointerId;
            }

            public getPos(key?: string): number {
                var name: string;
                if (key && this.getPointerId(key)) {
                    name = this.getPointerName(key);
                } else {
                    name = this.uniqueName();
                }
                return name.match(/^\d+$/) ? Number(name) : -1;
            }

            private addProperties(obj: any) {
                for (let key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (key !== "UniqueID") {
                            this[key] = obj[key];
                        }
                    }
                }
            }

            get isArray() {
                return this["@arr"];
            }

			addValueArray(array: any, item: any, i: number) {
				if (array[i] !== undefined) {
					array[i] = item;
				}
				else {
					array.push(item);
				}
			}

			removeValueAray(array: any, start: number, count: number) {
				array.splice(start, count);
			}

            private addOnArray(array: any, item: any) {
                array.splice(item.getPos(this.UniqueID), 0, item);
            }

            private addArrayMethods(array: any) {
                this.addSpliceMethod(array);
                this.addPushMethod(array);
                this.addSortMethod(array);
                // the array methods add the property "length" to the model, which should be ignored during delta updates
                this._internalProperties.push("length");
            }

            private addSpliceMethod(array: any) {
                if (!array.splice) {
                    Array.prototype.splice.apply(array);
                    array.splice = Array.prototype.splice;
                }
            }

            private addPushMethod(array: any) {
                if (!array.push) {
                    Array.prototype.push.apply(array);
                    array.push = Array.prototype.push;
                }
            }

            private addSortMethod(array: any) {
                if (!array.sort) {
                    Array.prototype.sort.apply(array);
                    array.sort = Array.prototype.sort;
                }
            }

            private removeOnArray(item: Model): boolean {
                var removed: boolean = false;
                const array = (this as any);
                if (item.getPos(this.UniqueID) > -1 && array[item.getPos(this.UniqueID)] && item.UniqueID === array[item.getPos(this.UniqueID)].UniqueID) {
                    array.splice(item.getPos(this.UniqueID), 1);
                    removed = true;
                } else {
                    const itemIndex = this.getPositionOnArray(item);
                    if (itemIndex > -1) {
                        array.splice(this.getPositionOnArray(item), 1);
                        removed = true;
                    }
                }
                return removed;
            }

            private getPositionOnArray(item: Contract.Core.IModel) {
                const array = (this as any);
                let position = -1;
                for (let i = 0; i < array.length; i++) {
                    if (item.UniqueID === array[i].UniqueID) {
                        position = i;
                        break;
                    }
                }
                return position;
            }

            private addPointerProperties(model: any) {
                // the original json object for a pointer has an structure like:
                // {@k:2, p["->property#object1#2", "proper2#panel1#2"]}
                // or
                // {@k:2, v["->value#object#2", "MyString"]}
                // and should be transformed into something like:
                // {@k:2, UniqueID:"property#object1#2", p:"proper2#panel1#2"]}
                // or
                // {@k:2, UniqueID:"value#object#2", v:"MyString"]}
                if (model.p) {
                    model._uniqueID = model.p[0].replace("->", "");
                    model.p = model.p[1];
                } else if (model.v) {
                    model._uniqueID = model.v[0].replace("->", "");
                    model.v = model.v[1];
                }
            }

            private getPointerId(key: string): string {
                return this._pointers[key];
            }

            private getPointerName(key: string): string {
                var pointerName = this.getPointerId(key);
                if (pointerName) {
                    const name = pointerName.split(Model.separator)[0];
                    return name === "_items" ? "Items" : name;
                } else {
                    return undefined;
                }
            }
        }
    }
}
