/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        import ClientBehavior = Mobilize.Contract.Core.IClientBehavior;
        import Entity = Mobilize.Contract.Core.IEntity;
        import IModel = Mobilize.Contract.Core.IModel;
        import Order = Contract.Core.Order;

        export class ModalVisibilityManagementBehavior implements ClientBehavior {
            aliasService: Contract.Core.ITypeService;
            eventAggregator: Contract.Application.IEventAggregator;

            constructor(private inject: Contract.Application.IInject = null) {
                this.inject = inject || Application.Inject.Instance;
                this.eventAggregator = this.inject.resolve(Contract.Application.Constants.EventAggregator);
            }

            apply(model: IModel, root: Entity) {
                if (model.IsModalView) {
                    model.onPropertyChange = (property: string, oldValue: any, newValue: any) => {
                        if (property === "visible" && oldValue !== newValue) {
                            if (newValue === true) {
                                this.eventAggregator.publish("navigateToView", model);
                            } else {
                                this.eventAggregator.publish("disposeView", model);
                            }
                        }
                    };
                }
            }

            get Order(): Order {
                return Order.POST;
            }
        }
    }
}

