/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        export class CommandFactory implements Contract.Ui.ICommandFactory {
            private factories: Contract.Application.IDictionary;

            constructor() {
                this.factories = new Application.Dictionary();
            }

            create(args: any): Mobilize.Contract.Ui.ICommand {
                const constructor = this.factories.value(args.id);
                if (!constructor) {
                    return null;
                }
                var command = new constructor(args);
                return command;
            }

            register(name: string, constructor: any) {
                this.factories.add(name, constructor);
            }
        }
    }
}
