/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Core {
        export class DirtyModel implements Contract.Core.IDirty {
            private _name: string;
            private _properties: Array<DirtyProperty>;

            constructor(name: string, properties: Array<DirtyProperty>) {
                this._name = name;
                this._properties = properties;
            }

            get name(): string { return this._name; }

            set name(value: string) {
                this._name = value;
            }

            get properties(): Array<DirtyProperty> { return this._properties; }

            set properties(value: Array<DirtyProperty>) {
                this._properties = value;
            }

            toReduce(): any {
                return this.minProperties();
            }

            private minProperties() {
                var props = {};
                this.properties.forEach((property) => {
                    props[property.name] = property.value;
                });
                return props;
            }
        }
    }
}
